<template>
  <div class="contact-forms">
      <div class="header">
        <div class="search">
        <span class="p-input-icon-left" style="width:100%">
        <i class="pi pi-search" />
        <InputText
            type="text"
            v-model="search"
            placeholder="חיפוש"
            style="width:100%"
        />
        </span>
        </div>
        <div class="date-from">
            <input type="date" v-model="fromDate" />
        </div>
        <div class="date-to">
            <input type="date" v-model="toDate" />
        </div>
        <div class="clear-dates">
             <el-button type="primary" @click="handleClearDates">איפוס תאריכים</el-button>
        </div>
      </div>
      <div class="dashboard">
        <div class="frame total">
        <p>סה"כ טפסים</p>
        <p>{{counter_values.total}}</p>
        </div>
        <div class="frame news">
            <p>טפסים חדשים</p>
            <p>{{counter_values.new}}</p>
        </div>
        <div class="frame old">
            <p>טפסים שטופלו</p>
            <p>{{counter_values.old}}</p>
        </div>
      </div>
      <div class="display-btns">
           <el-button style="margin-left:50px" type="success" @click="handle_records('new')">הצג חדשים</el-button>
           <el-button type="danger" @click="handle_records('old')">הצג טופלו</el-button>
      </div>
      <div v-loading="isPending" class="content">
        <table id="records">
            <tr>
                <th @click="handleSortTable('נוצר בתאריך', !selectedSorted.order)">נוצר בתאריך</th>
                <th @click="handleSortTable('שם החברה', !selectedSorted.order)">שם החברה</th>
                <th @click="handleSortTable('שם פרטי', !selectedSorted.order)">שם פרטי</th>
                <th @click="handleSortTable('טלפון', !selectedSorted.order)">טלפון</th>
                <th @click="handleSortTable('דואל', !selectedSorted.order)">דוא"ל</th>
                <th>סטטוס</th>
                <th>מידע</th>
                <th>
                    <Checkbox v-model="checkeAll" :binary="true" />
                </th>
            </tr>
            <h1  style="text-align:center;" v-if="sorted_forms.length==0 && !isPending">אין רשומות</h1>
             <template v-for="form in sorted_forms" :key="form.id">
                 <tr>
                    <td>{{form.createdAt.toDate().toLocaleDateString('he')}}</td>
                    <td>{{form.company_name}}</td>
                    <td>{{form.full_name}}</td>
                    <td>{{form.phone}}</td>
                    <td>{{form.email}}</td>
                    <td v-if="form.status=='new'" style="color:green">חדש</td>
                    <td v-if="form.status=='old'" style="color:red">טופל</td>
                    <td><i class="material-icons" @click="handle_form_info(form)">info</i></td>
                    <td>
                        <Checkbox
                            name="form"
                            :value="form"
                            v-model="checkForms"
                        />
                    </td>
                 </tr>
             </template>
        </table>
      </div>
  </div>
    <Dialog
            header="חלון מחיקה"
            v-model:visible="showDeleteDialog"
            :style="{ width: '30vw' }"
            position="bottom"
        >
            <p class="p-m-0">האם את/ה בטוח שברצונך למחוק את הרשומות המסומנות?</p>
            <template #footer>
            <Button
                label="לא"
                @click="handleDeleteChecks('לא')"
                class="p-button-success"
            />
            <Button
                label="כן"
                @click="handleDeleteChecks('כן')"
                class="p-button-danger"
            />
            </template>
    </Dialog>
    <Dialog header="חלון מידע ופעולות" v-model:visible="show_info" >
        <div class="info-and-actions">
            <div class="info-header">
                <div class="info-header-field">
                    <p>שם פרטי</p>
                    <p>{{current_form.full_name}}</p>
                </div>
                <div class="info-header-field">
                    <p>שם חברה</p>
                    <p>{{current_form.company_name}}</p>
                </div>
                <div class="info-header-field">
                    <p>טלפון</p>
                    <p>{{current_form.phone}}</p>
                </div>
                <div class="info-header-field">
                    <p>דוא"ל</p>
                    <p>{{current_form.email}}</p>
                </div>
            </div>
            <div class="info-contect">
                <p>תוכן ההודעה:</p>
                <p>{{current_form.msg}}</p>
            </div>
            <div class="info-actions">
                 <el-select v-model="current_form.status" placeholder="Select">
                    <el-option
                        v-for="status in status_options"
                        :key="status.value"
                        :label="status.label"
                        :value="status.value"
                    >
                    </el-option>
                </el-select>
                <el-button @click="handle_update_status" style="margin-right:5px;" type="primary">עדכן</el-button>
            </div>
        </div>
    </Dialog>   
</template>

<script>
import InputText from "primevue/inputtext";
import Dialog from 'primevue/dialog';
import { ref } from '@vue/reactivity';
import { computed, onMounted, onUnmounted, watch } from '@vue/runtime-core';
import {projectFirestore,firebase} from '../../../firebase/config'
import Checkbox from 'primevue/checkbox';
import {alert} from '../../../Methods/Msgs'
export default {
    components:{InputText,Checkbox,Dialog},
    setup(){
        const status_options = ref([
            {
                value:'new',
                label:'חדש'
            },
            {
                value:'old',
                label:'טופל'
            },
        ])
        const show_info = ref(false)
        const current_form = ref(null)
        const current_status = ref('')

        const counter_values = ref({
          new:0,
          old:0,
          total:0,
        })
        const isPending = ref(false)
        const search = ref('')
        const fromDate = ref('')
        const toDate = ref('')
        const checkeAll = ref(false)
        const checkForms=ref([])
        const showDeleteDialog = ref(false)

        const handle_update_status = async()=>{
            if(current_form.value.status!=current_status.value){
                await projectFirestore.collection('Contact').doc('Contact').collection('Forms').doc(current_form.value.id).set({
                    status:current_form.value.status
                },{merge:true})

                await update_status_counter(current_status.value,current_form.value.status)

                alert('success','הצלחה',`סטטוס של טופס זה שונה בהצלחה ל${current_form.value.status}`)
                .then(()=>{
                    const index = records.value.findIndex(record=>record.id==current_form.value.id)
                    if(index!=-1){
                        records.value.splice(index,1)
                    }
                    show_info.value = false
                })


            }
            
        }

        const handle_form_info=(form)=>{
            current_form.value = form
            current_status.value = form.status
            show_info.value = !show_info.value
        }
        const handleDeleteChecks=async(option)=>{
            if(option=='לא'){
                showDeleteDialog.value=false
                checkForms.value=[]
                checkeAll.value=false
            }
            if(option=='כן'){
                isPending.value = true
                for (const record of checkForms.value){
                    await decrement(record.status)
                    await delete_form(record.id)
                    const index = records.value.findIndex(_record=>_record.id == record.id)
                    records.value.splice(index,1)
                }
                showDeleteDialog.value=false
                checkForms.value=[]
                isPending.value=false
                checkeAll.value=false
            }
        }

        const delete_form=async(id)=>{
            await projectFirestore.collection('Contact').doc('Contact').collection('Forms')
            .doc(id).delete()
        }

        const decrement = async(status)=>{
            await projectFirestore.collection('Contact').doc('Contact')
            .update({
                [status]:firebase.firestore.FieldValue.increment(-1),
                total:firebase.firestore.FieldValue.increment(-1)
            })
        }
        const update_status_counter = async(old_status,new_status)=>{
            await projectFirestore.collection('Contact').doc('Contact')
            .update({
                [old_status]:firebase.firestore.FieldValue.increment(-1),
                [new_status]:firebase.firestore.FieldValue.increment(1)
            })
        }

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });
        const sorted_forms = ref(computed(()=>{
            if (selectedSorted.value.name == "")return filter_records_by_search.value;
            if (selectedSorted.value.name == "נוצר בתאריך") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.createdAt.toDate() >  b.createdAt.toDate() 
                        ? 1
                        : b.createdAt.toDate()  > a.createdAt.toDate() 
                        ? -1
                        : 0;
                    }
                    return a.createdAt.toDate() > b.createdAt.toDate() 
                    ? -1
                    : b.createdAt.toDate()  > a.createdAt.toDate() 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם החברה") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.company_name >  b.company_name 
                        ? 1
                        : b.company_name  > a.company_name 
                        ? -1
                        : 0;
                    }
                    return a.company_name > b.company_name 
                    ? -1
                    : b.company_name  > a.company_name 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם פרטי") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.full_name >  b.full_name 
                        ? 1
                        : b.full_name  > a.full_name 
                        ? -1
                        : 0;
                    }
                    return a.full_name > b.full_name 
                    ? -1
                    : b.full_name  > a.full_name 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "טלפון") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.phone >  b.phone 
                        ? 1
                        : b.phone  > a.phone 
                        ? -1
                        : 0;
                    }
                    return a.phone > b.phone 
                    ? -1
                    : b.phone  > a.phone 
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "דואל") {
                return filter_records_by_search.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.email >  b.email 
                        ? 1
                        : b.email  > a.email 
                        ? -1
                        : 0;
                    }
                    return a.email > b.email 
                    ? -1
                    : b.email  > a.email 
                    ? 1
                    : 0;
                });
            }
        }))
        const filter_records_by_search =ref(computed(()=>{
             if(search.value == '') {
                 if(fromDate.value && toDate.value){
                    return records.value.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                        stringTodate(toDate.value)>=timestampToDate(record.createdAt)){
                            return record
                        }
                    })
                 }
                 return records.value;
             }
             else{
                let results = records.value.filter(record=>{
                    if(new Date(record.createdAt.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record
                    if (record.company_name.includes(search.value)) return record;
                    if (record.email.includes(search.value)) return record;
                    if (record.full_name.includes(search.value)) return record;
                    if (record.phone.includes(search.value)) return record;
                })
                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                        stringTodate(toDate.value)>=timestampToDate(record.createdAt)){
                            return record
                        }
                    })
                }else{
                    return results
                }
             }
        }))
        const handleClearDates = ()=>{
            fromDate.value=''
            toDate.value=''
        }
        const records = ref([])

        const handle_records = async(status)=>{
            isPending.value = true
            const docs = await projectFirestore.collection('Contact').doc('Contact')
            .collection('Forms').where('status','==',status).orderBy('createdAt','desc').get()
            records.value = docs.docs.map(doc=>doc.data())
            isPending.value = false
        }

        const initial = async()=>{
            let status='new'
            // if(localStorage.getItem('Evenet_report_search')){
            //         search.value = localStorage.getItem('Evenet_report_search')
            // }
            // if(localStorage.getItem("Evenet_report_status")){
            //         status=localStorage.getItem("Evenet_report_status")
            // }
            await handle_records(status)
        }

        let counter_unsub
        const counter_records_RT=()=>{
            counter_unsub = projectFirestore.collection('Contact').doc('Contact')
            .onSnapshot(doc=>{
                counter_values.value = doc.data()
            })
        }

         // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        watch(checkeAll,()=>{
            if(checkeAll.value){
                checkForms.value=[...sorted_forms.value]
            }
            else{
                checkForms.value=[]
            }
        })

        watch(checkForms,()=>{
            if(checkForms.value.length>0){
                showDeleteDialog.value=true
            }
            else {
                showDeleteDialog.value=false
            }
        })

        onMounted(async()=>{
             await initial()
             counter_records_RT()
        })

        onUnmounted(()=>{
            if(counter_unsub){
                counter_unsub()
            }
        })

        return{
            handleClearDates,
            handleSortTable,
            handle_records,
            handleDeleteChecks,
            handle_form_info,
            handle_update_status,
            current_form,
            showDeleteDialog,
            checkeAll,
            checkForms,
            sorted_forms,
            selectedSorted,
            toDate,
            fromDate,
            search,
            records,
            isPending,
            counter_values,
            filter_records_by_search,
            show_info,
            status_options
        }
    }

}
</script>

<style scoped>
    .contact-forms{
        width: 100%;
        height: 100%;
        background: #fff;
    }
    .header{
        padding: 1.5rem;
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center; 
    }
    input[type="date"] {
        height: 35px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .dashboard{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: space-around;
        padding: 0 20px;
    }
    .dashboard .frame{
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 10px;
    }
    .dashboard .total{
        background-color: teal;
    }
    .dashboard .news{
        background-color: #027ABC;
    }
    .dashboard .old{
        background-color: #5E8F32;
    }
    .display-btns{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content{
        position: relative;
        width: 100%;
        height: 75%;
        overflow: hidden;
        overflow-y: auto;
    }
    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    #records td, #records th {
        border: 1px solid #ddd;
        padding: 2px;
        text-align: center;
    }
    #records tr:nth-child(even){
        background-color: #f2f2f2;
    }
    #records tr:hover {
        background-color: #ddd;
    }
    #records th {
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #7367f0;
        color: white;
    }
    i{
        cursor: pointer;
    }
    i:hover{
        color: darkslateblue;
    }
    .info-and-actions{
        width: 40vw;
        height: 40vh;
    }
    .info-header{
        width: 100%;
        height: 10%;
        display: flex;
    }
    .info-contect{
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;
        overflow:hidden;
        overflow-y: auto;
    }
    .info-contect p{
        font-size: 25px;
        white-space: pre-wrap;
    }
    .info-actions{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
    }
    .info-header-field{
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }
    .info-header-field p{
         font-weight: bold;
    }




</style>