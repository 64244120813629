<template>
  <div class="system-update">
       <div class="system-update-form">
           <div class="header">
               <h1>מסך עדכון מערכת</h1>
           </div>
           <div class="text-area">
               <h3>נא לרשום עדכונים אחרונים שבוצעו</h3>
               <el-input
                    v-model="update_text"
                    :rows="12"	
                    type="textarea"
                    placeholder="עדכונים אחרונים..."
                    :input-style="{'font-size': '18px', 'white-space': 'pre-wrap'}"
                />
           </div>
           <div class="submit-btn">
                <el-button @click="handle_save_update" style="width:100%; height:100%;" type="primary">שמור</el-button>
           </div>
       </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {projectFirestore} from '../../../firebase/config'
import {slide_pop_successs} from '../../../Methods/Msgs'
import { onMounted } from '@vue/runtime-core'
export default {
    setup(){
        const update_text = ref('')
        const handle_save_update = async()=>{
            await projectFirestore.collection('Public').doc('Update_review')
            .set({
                text:update_text.value
            },{merge:true})
            slide_pop_successs('העדכון בוצע בהצלחה')
        }

        const get_last_updates_from_db=async()=>{
            const doc = await projectFirestore.collection('Public').doc('Update_review').get()
            update_text.value = doc.data().text
        }

        onMounted(async()=>{
            await get_last_updates_from_db()
        })
        return{update_text,handle_save_update}
    }
}
</script>

<style scoped>
    .system-update{
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        color: #fff;
    }
    .system-update-form{
        width: 375px;
        height: 100%;
        margin-bottom: 5px;
        text-align: center;
        overflow:hidden;
        overflow-y: auto;
    }
    .header{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .text-area{
        width: 100%;
        max-width: 360px;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }
   
    .submit-btn{
        width: 100%;
        max-width: 360px;
        height: 10%;
    }
   
</style>