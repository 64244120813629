<template>
  <div class="jobs-main">
      <div class="jobs-header">
          <div @click="state='מודעה חדשה'" class="jobs-header-btn">
              <p>מודעה חדשה</p>
          </div>
          <div @click="state='מודעות'" class="jobs-header-btn">
              <p>מודעות</p>
          </div>
      </div>
      <div class="jobs-content">
          <CreateNewJob v-if="state=='מודעה חדשה'" />
          <JobsManage @edit="handle_edit($event)" v-if="state=='מודעות'" />
          <CreateNewJob @close="state='מודעות'" :edit="edit" v-if="state=='עריכת מודעה'" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { defineAsyncComponent } from '@vue/runtime-core'
export default {
    components:{
        CreateNewJob:defineAsyncComponent(()=>import('../jobs/CreateNewJob.vue')),
        JobsManage:defineAsyncComponent(()=>import('../jobs/JobsManage.vue'))
    },
    setup(){
        const edit = ref(null)
        const state = ref('מודעה חדשה')
        const handle_edit = (id)=>{
            edit.value = id
            state.value = 'עריכת מודעה'
        }
        
        return{state,edit,handle_edit}
    }
}
</script>

<style scoped>
    .jobs-main{
        width: 100%;
        height: 100%;
    }
    .jobs-header{
        width: 100%;
        height: 5%;
        display: flex;
        overflow:hidden;
        overflow-x: auto;
    }
    .jobs-header-btn{
        width: 120px;
        height: 100%;
        background: linear-gradient(123deg ,#7367f0,rgba(110,100,238,.7));
        border-radius: 5px;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 0 4px 1px rgb(110 100 238 / 20%);
        margin-right: 5px;
        flex-shrink: 0;
    }
    .jobs-header-btn:active{
        transform: scale(1.01);
    }
    .jobs-content{
        width: 100%;
        height: 95%;
    }
</style>